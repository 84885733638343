import { useSelector } from "react-redux";

type Permission = {
  id: number;
  path: string;
  action: string;
  scope: string;
};
const useHavePermission = (keys: string[]) => {
  const permissions = useSelector(
    (state: any) => state.permissions.permissions
  );
  const loading = useSelector((state: any) => state.permissions.loading);
  const userData = useSelector((state: UserGlobalState) => state.user.userData);
  // get ids of page keys
  const keysId = keys.map((item) => {
    if (!loading) {
      const per = permissions.find(
        (per: { path: string; action: string; scope: string }) =>
          `${per.path}:${per.action}:${per.scope}` === item
      );
      return per?.id || null;
    }
  });
  //   if one of page keys is on the user permission so true
  const havePermission = () => {
    if (!loading) {
      return keysId.some((key) => userData.permissions.includes(key));
    }
  };
  return { loading, havePermission };
};

export default useHavePermission;
