import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
// fetch
import { useFetchApi } from "../../hooks/useFetchApi";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useSelector } from "react-redux";
import useHavePermission from "../../hooks/useHavePermission";

type TProps<T> = {
  filterValues: T;
  setFilterValues: Dispatch<SetStateAction<T>>;
  filterWallet?: boolean;
};
const UserFilter = <T,>({
  filterValues,
  setFilterValues,
  filterWallet = false,
}: TProps<T>) => {
  const { loading: loadingPermissions, havePermission } = useHavePermission([
    "/api/v1/users:index:admin",
  ]);
  const isAllowed = havePermission();

  return isAllowed ? (
    <UserSelect filterValues={filterValues} setFilterValues={setFilterValues} />
  ) : (
    <></>
  );
};

export default UserFilter;

const UserSelect = <T,>({ setFilterValues }: TProps<T>) => {
  const userToken = useSelector(
    (state: UserGlobalState) => state.user.userToken
  );
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);

  const handleAutocompleteChange = (e: any, newValue: any) => {
    setFilterValues((prev) => ({
      ...prev,
      user_id: newValue ? `user_id:${newValue.id}` : "",
    }));
  };
  const {
    data: users,
    loading,
    error,
  } = useFetchApi(`/users?paginate=${"9999"}`, userToken, []);
  return (
    <Autocomplete
      // size="small"
      sx={{ flex: "1", minWidth: "230px" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option: User, value: User) =>
        option.id === value.id
      }
      getOptionLabel={(option: User) => option.name}
      options={users.data}
      loading={loading}
      onChange={handleAutocompleteChange}
      value={value ?? null}
      renderInput={(params) => (
        <TextField
          {...params}
          label="المستخدم"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <AiOutlineLoading3Quarters /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
