import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import permissionsSlice from "./features/permissions/permissionsSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    permissions: permissionsSlice,
  },
});
