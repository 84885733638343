import { Dispatch, SetStateAction } from "react";
// mui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from "@mui/material";

type TSortOptions = {
  sortTitle: string;
  sortKey: string;
};
type TProps = {
  sortValue: string;
  setSortValue: Dispatch<SetStateAction<string>>;
  sortingOptions?: TSortOptions[];
};
const defaultSortingOptions: TSortOptions[] = [
  {
    sortTitle: "الاسم تنازلياً",
    sortKey: "name",
  },
  {
    sortTitle: "الاسم تصاعدياً",
    sortKey: "-name",
  },
  {
    sortTitle: "تاريخ الانشاء من الاحدث الى الاقدم",
    sortKey: "-created_at",
  },
  {
    sortTitle: "تاريخ الانشاء من الاقدم الى الاحدث",
    sortKey: "created_at",
  },
  {
    sortTitle: "تاريخ التحديث من الاحدث الى الاقدم",
    sortKey: "-updated_at",
  },
  {
    sortTitle: "تاريخ التحديث من الاقدم الى الاحدث",
    sortKey: "updated_at",
  },
];

const SortFilter = ({
  sortValue,
  setSortValue,
  sortingOptions = defaultSortingOptions,
}: TProps) => {
  const handleChange = (e: SelectChangeEvent<string>) => {
    setSortValue(e.target.value);
  };
  return (
    <Box sx={{ flex: "1", minWidth: "230px" }}>
      <FormControl fullWidth>
        <InputLabel>ترتيب حسب</InputLabel>
        <Select value={sortValue} label="ترتيب حسب" onChange={handleChange}>
          {sortingOptions.map((option: TSortOptions) => (
            <MenuItem value={option.sortKey} key={option.sortKey}>
              {option.sortTitle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortFilter;
