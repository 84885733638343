import { IconType } from "react-icons";
import { MdOutlineHome, MdOutlineSms } from "react-icons/md";
import { TbUsers, TbMessageUp } from "react-icons/tb";
import { LuKey } from "react-icons/lu";
import { BsCardList, BsSend, BsSendPlus } from "react-icons/bs";
import { CiSquareMore } from "react-icons/ci";
import { BiMessageRoundedDetail } from "react-icons/bi";

interface TAdminLink {
  title: string;
  icon: IconType;
  path: string;
  key: string[];
}

export const adminLinks: TAdminLink[] = [
  {
    title: "الرئيسية",
    icon: MdOutlineHome,
    path: "/",
    key: ["/api/v1/statistics:index:admin", "/api/v1/statistics:index:own"],
  },
  {
    title: "ميجا SMS",
    icon: MdOutlineSms,
    path: "/mega-sms",
    key: ["/api/v1/balance-check:balance-check:admin"],
  },
  {
    title: "المستخدمين",
    icon: TbUsers,
    path: "/users",
    key: ["/api/v1/users:index:admin"],
  },
  {
    title: "الادوار",
    icon: LuKey,
    path: "/roles",
    key: ["/api/v1/roles:index:public", "/api/v1/roles:index:own"],
  },
  {
    title: " عمليات الدفع",
    icon: BsCardList,
    path: "/transactions",
    key: [
      "/api/v1/wallet-transactions:index:admin",
      "/api/v1/wallet-transactions:index:own",
      "/api/v1/roles:index:public",
    ],
  },
  {
    title: "رسائل التحقق",
    icon: BiMessageRoundedDetail,
    path: "/pins/history",
    key: ["/api/v1/pin-history:index:admin", "/api/v1/pin-history:index:own"],
  },
  {
    title: "الرسائل المرسلة",
    icon: TbMessageUp,
    path: "/messages",
    key: ["/api/v1/messages:index:admin", "/api/v1/messages:index:own"],
  },
  {
    title: "ارسال رسالة",
    icon: BsSend,
    path: "/messages/push",
    key: ["/api/v1/messages:store:own", "/api/v1/messages:store:admin"],
  },
  {
    title: "ارسال رسالة لاكثر من مستخدم",
    icon: BsSendPlus,
    path: "/messages/push/multi",
    key: [
      "/api/v1/messages/bulk-send:bulk-send:own",
      "/api/v1/messages/bulk-send:bulk-send:admin",
    ],
  },
];
