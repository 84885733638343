import React from "react";
// r redux
import { useSelector } from "react-redux";
// mui
import { Skeleton, Stack } from "@mui/material";
// components
import StatisticsCard from "../../components/cards/StatisticsCard";
import PageWrapper from "../../components/page/PageWrapper";
import PageTitle from "../../components/page/PageTitle";
import PageGuard from "../../components/page/PageGuard";
import ThreeDotsLoader from "../../components/loader/ThreeDotsLoader";
// fetch
import { useFetchApi } from "../../hooks/useFetchApi";
// icons
import { BiUser, BiMessageDetail } from "react-icons/bi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { IoCalendarOutline } from "react-icons/io5";
// hooks
import useHavePermission from "../../hooks/useHavePermission";
// dayjs time formatter
import dayjs from "dayjs";

// utils
const randomDarkColors: string[] = [
  "#F94144",
  "#F3722C",
  "#F8961E",
  "#F9844A",
  "#F9C74F",
  "#90BE6D",
  "#43AA8B",
  "#4D908E",
  "#577590",
  "#277DA1",
];

const Home = () => {
  const userToken = useSelector(
    (state: UserGlobalState) => state.user.userToken
  );
  // const userData = useSelector((state: UserGlobalState) => state.user.userData);

  const {
    data: statistics,
    loading,
    error,
  } = useFetchApi(`/statistics`, userToken, []);
  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useFetchApi(`/me`, userToken, []);

  const { havePermission } = useHavePermission([""]);
  const isAdmin = havePermission();

  // if (loading || userDataLoading) return <ThreeDotsLoader />;
  return (
    <PageGuard
      pageKey={[
        "/api/v1/statistics:index:admin",
        "/api/v1/statistics:index:own",
      ]}
    >
      <PageWrapper>
        <PageTitle title="الرئيسية" />
        {loading || userDataLoading ? (
          <Stack
            direction="row"
            justifyContent="center"
            gap={2}
            flexWrap="wrap"
          >
            {Array(4).map((item) => (
              <CardSkeleton />
            ))}
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            gap={2}
            flexWrap="wrap"
          >
            {isAdmin ? (
              <StatisticsCard
                title="عدد المشتركين "
                value={statistics.total_users}
                Icon={BiUser}
                color={randomDarkColors[0]}
              />
            ) : (
              <></>
            )}
            <StatisticsCard
              title="اجمالي عدد الرسائل المرسلة "
              value={statistics.total_prd_count}
              Icon={BiMessageDetail}
              color={randomDarkColors[1]}
            />
            <StatisticsCard
              title="اجمالي قيمة الرسائل المرسلة "
              value={statistics.total_prd_value}
              Icon={MdOutlineAttachMoney}
              color={randomDarkColors[2]}
            />
            {isAdmin ? (
              <></>
            ) : (
              <StatisticsCard
                title="اجمالي قيمة الرصيد "
                value={userData?.wallet?.credit}
                Icon={HiOutlineBanknotes}
                color={randomDarkColors[3]}
              />
            )}
            {!isAdmin && userData?.account_expiry ? (
              <StatisticsCard
                title="تاريح انتهاء الاشتراك"
                value={dayjs(userData?.account_expiry.toString()).format(
                  "YYYY/MM/DD"
                )}
                Icon={IoCalendarOutline}
                color={randomDarkColors[4]}
              />
            ) : (
              <></>
            )}
          </Stack>
        )}
        {/* vendor info  */}
      </PageWrapper>
    </PageGuard>
  );
};
export default Home;

const CardSkeleton = () => {
  return (
    <Skeleton width="266px" height="74px" sx={{ transform: "scale(1,1)" }} />
  );
};
