import { Stack } from "@mui/material";
import React, { ReactNode } from "react";

const FiltersContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      {children}
    </Stack>
  );
};

export default FiltersContainer;
