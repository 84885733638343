import React, { SetStateAction, Dispatch } from "react";
import { Pagination } from "@mui/material";
type TProps = {
  totalItems: number;
  itemsPerPage: number;
  page: number;
  setPage: (n: number) => void;
};
const PagePagination = ({
  totalItems,
  itemsPerPage,
  page,
  setPage,
}: TProps) => {
  return totalItems > 0 ? (
    <Pagination
      count={Math.ceil(totalItems / itemsPerPage)}
      page={page}
      defaultPage={1}
      onChange={(event, value) => {
        setPage(value);
      }}
      color="primary"
      variant="outlined"
      sx={{ margin: "auto" }}
    />
  ) : (
    <></>
  );
};

export default PagePagination;
