import { PaletteOptions } from "@mui/material";
export const lightPalette = {
  primary: {
    main: "#083851",
  },
  secondary: {
    main: "#5394ac",
  },
  textColor: {
    main: "#000",
    secondary: "#999",
    xmain: "#fff",
  },
  basicColor: {
    white: "#fff",
    black: "#000",
  },
};
