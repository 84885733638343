import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToken: localStorage.getItem("user_token") ?? "",
  userData: localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data") ?? "{}")
    : {},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
      localStorage.setItem("user_token", action.payload.toString());
    },
    removeUserToken: (state) => {
      state.userToken = "";
      localStorage.removeItem("user_token");
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
      localStorage.setItem("user_data", JSON.stringify(action.payload));
    },
    removeUserData: (state) => {
      state.userData = {};
      localStorage.removeItem("user_data");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserToken, removeUserToken, setUserData, removeUserData } =
  userSlice.actions;

export default userSlice.reducer;
