import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../../api/apiFetch";

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async () => {
    const res = await apiFetch("/permissions?paginate=99999", "GET");
    const data = res.json();
    return data;
  }
);

const initialState = {
  permissions: [],
  loading: true,
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload.data;
      state.loading = false;
    });
  },
});

export default permissionsSlice.reducer;
