import React from "react";
// mui
import { Stack, Box, Typography } from "@mui/material";
// icons
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineMessage, MdOutlineAccessTime } from "react-icons/md";
import dayjs from "dayjs";

type Props = {
  phone: string;
  content: string;
  requested_at: string;
};
const OtpMessageCard = ({ phone, content, requested_at }: Props) => {
  return (
    <Stack
      width="100%"
      sx={{
        boxShadow: 1,
        p: 2,
        gap: "8px",
        minWidth: "300px",
        maxWidth: "500px",
      }}
    >
      <Stack
        direction="row"
        gap="8px"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Stack direction="row" gap="5px" alignItems="center">
          <Typography variant="h6" lineHeight="0">
            <IoPhonePortraitOutline />
          </Typography>
          <Typography variant="subtitle1">{phone}</Typography>
        </Stack>
        <Stack
          direction="row"
          gap="5px"
          alignItems="center"
          sx={{
            backgroundColor: "primary.light",
            color: "#fff",
            padding: "2px",
            borderRadius: "2px",
          }}
        >
          <Typography variant="h6" lineHeight="0">
            <MdOutlineAccessTime />
          </Typography>
          <Typography>
            {dayjs(requested_at).format("YYYY/MM/DD - HH:mm")}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap="5px" alignItems="center">
        <Typography variant="h6" lineHeight="0">
          <MdOutlineMessage />
        </Typography>
        <Typography variant="subtitle1">{content}</Typography>
      </Stack>
    </Stack>
  );
};

export default OtpMessageCard;
