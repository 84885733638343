import { useEffect, useState } from "react";
import { apiFetch } from "../api/apiFetch";
import usePageParams from "./usePageParams";

export const useFetchApi = (
  endpoint: string,
  token: string | null = null,
  dependencies: any[] = [],
  callbackFn?: (data: any) => void
) => {
  const [data, setData] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { page } = usePageParams();
  useEffect(() => {
    setLoading(true);
    apiFetch(
      endpoint,
      "GET",
      { Authorization: token ? `Bearer ${token}` : "" },
      null
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        if (callbackFn) {
          callbackFn(data);
        }
      })
      .catch((error) => setError(error));
  }, [...dependencies]);
  return { data, error, loading };
};
