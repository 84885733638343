import React, { useState } from "react";
// r router
import { useNavigate } from "react-router";
// r redux
import { useSelector, useDispatch } from "react-redux";
// mui
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// components
import ConfirmationAlert from "../alerts/ConfirmationAlert";
import DialogWrapper from "../dialog/DialogWrapper";
// fetch
import { apiFetch } from "../../api/apiFetch";
// alerts
import { fireAlert } from "../../lib/fireAlert";
// icons
import { IoIosLogOut } from "react-icons/io";
// redux action
import {
  removeUserData,
  removeUserToken,
} from "../../redux/features/user/userSlice";

const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector(
    (state: UserGlobalState) => state.user.userToken
  );
  const [open, setOpen] = useState(false);
  const logoutUser = async () => {
    setOpen(false);
    const logoutRes = await apiFetch(`/logout`, "GET", {
      Authorization: `Beaer ${userToken}`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          throw new Error(data.errors);
        }
        dispatch(removeUserToken());
        dispatch(removeUserData());
        fireAlert("تم تسجيل الخروج  بنجاح ", "success");
        navigate("/login");
      })
      .catch((error) => {
        fireAlert(error.toString(), "error");
      });
  };
  return (
    <>
      <ListItem disablePadding sx={{ color: "white" }}>
        <ListItemButton component={Button} onClick={() => setOpen(true)}>
          <ListItemIcon
            sx={{ fontSize: "22px", minWidth: "32px", color: "inherit" }}
          >
            <IoIosLogOut />
          </ListItemIcon>
          <ListItemText primary={"تسجيل الخروج"} />
        </ListItemButton>
      </ListItem>
      <DialogWrapper open={open} setOpen={setOpen}>
        <ConfirmationAlert
          title="هل آنت متاكد انك تريد تسجيل الخروج"
          confirmFn={logoutUser}
          cancelFn={() => setOpen(false)}
        />
      </DialogWrapper>
    </>
  );
};

export default LogoutButton;
