import React, { lazy, Suspense } from "react";
// r router
import { createBrowserRouter } from "react-router-dom";
// layouts
import AdminLayout from "../layouts/admin/AdminLayout";
// protected routes
import ProtectedRoutes from "../components/protectedRoutes/ProtectedRoutes";
import UserExist from "../components/protectedRoutes/UserExist";
// loader
import ThreeDotsLoader from "../components/loader/ThreeDotsLoader";
// pages
import Home from "../pages/home/Home";
import Login from "../pages/auth/login";
import PinsHistory from "../pages/pins/PinsHistory";
import SendMessage from "../pages/send-message/SendMessage";
import Welcome from "../pages/welcome/Welcome";

const Users = lazy(() => import("../pages/users/Users"));
const AddUser = lazy(() => import("../pages/users/AddUser"));
const EditUser = lazy(() => import("../pages/users/EditUser"));
const Roles = lazy(() => import("../pages/roles/Roles"));
const AddRole = lazy(() => import("../pages/roles/AddRole"));
const EditRole = lazy(() => import("../pages/roles/EditRole"));
const Transactions = lazy(() => import("../pages/transactons/Transactions"));
const Pins = lazy(() => import("../pages/pins/Pins"));
const MegaSms = lazy(() => import("../pages/messages/MegaSms"));
const PushMessage = lazy(() => import("../pages/push-message/PushMessage"));
const PushMessageMulti = lazy(
  () => import("../pages/push-message/PushMessageMulti")
);
const Messages = lazy(() => import("../pages/messages/Messages"));
// all routes
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoutes>
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      // home
      {
        index: true,
        element: <Home />,
      },
      //welcome
      {
        path: "welcome",
        children: [
          {
            index: true,
            element: <Welcome />,
          },
        ],
      },
      // mega sms
      {
        path: "mega-sms",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <MegaSms />
              </Suspense>
            ),
          },
        ],
      },
      // users
      {
        path: "users/",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <Users />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <AddUser />
              </Suspense>
            ),
          },
          {
            path: ":userId",
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <EditUser />
              </Suspense>
            ),
          },
        ],
      },
      // roles
      {
        path: "roles",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <Roles />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <AddRole />
              </Suspense>
            ),
          },
          {
            path: ":roleId",
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <EditRole />
              </Suspense>
            ),
          },
        ],
      },
      // transacions
      {
        path: "transactions/",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <Transactions />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "messages",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <Messages />
              </Suspense>
            ),
          },
          {
            path: "push",
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<ThreeDotsLoader />}>
                    <PushMessage />
                  </Suspense>
                ),
              },
              {
                path: "multi",
                element: (
                  <Suspense fallback={<ThreeDotsLoader />}>
                    <PushMessageMulti />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "pins/history",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ThreeDotsLoader />}>
                <PinsHistory />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  // auth
  {
    path: "/login",
    children: [
      {
        index: true,
        element: (
          <UserExist>
            <Login />
          </UserExist>
        ),
      },
    ],
  },
  // public for events
  {
    path: "/user/sendmessage",
    children: [
      {
        index: true,
        element: <SendMessage />,
      },
    ],
  },
]);
export default router;
