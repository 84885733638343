import React from "react";
import PageWrapper from "../../components/page/PageWrapper";
import { Stack, Typography } from "@mui/material";

const Welcome = () => {
  return (
    <PageWrapper>
      <Stack alignItems="center">
        <img
          src="/logo_no_bg.png"
          alt="Resala logo"
          width="256px"
          height="256px"
        />
        <Typography variant="h5">مرحباً بك في رسالة</Typography>
      </Stack>
    </PageWrapper>
  );
};

export default Welcome;
