import React from "react";
// mui
import { Button, Typography } from "@mui/material";
type TProps = {
  label: string;
  disabled?: boolean;
  extraStyles?: any;
};
const SubmitButton = ({ label, disabled, extraStyles }: TProps) => {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      type="submit"
      sx={{ ...extraStyles }}
    >
      <Typography variant="subtitle1">{label}</Typography>
    </Button>
  );
};

export default SubmitButton;
