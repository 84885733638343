import React, { ReactElement, ReactNode, useEffect, useState } from "react";
// react router
import { Outlet } from "react-router";
// components
import SideDrawer from "../../components/drawer/SideDrawer";
import BackwardButton from "../../components/buttons/BackwardButton";
// mui
import { Container, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchPermissions } from "../../redux/features/permissions/permissionsSlice";

const AdminLayout = () => {
  const [hideNavbar, setHideNavbar] = useState(false);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);
  return (
    <Stack direction="row">
      {/* <Sidebar hideNavbar={hideNavbar} setHideNavbar={setHideNavbar} /> */}
      <SideDrawer />
      <Stack
        flex="1"
        sx={{
          maxHeigth: "100vh",
          height: "100vh",
          overflow: "auto",
          // backgroundColor: "#f2f2f2",
        }}
      >
        <Container>
          <Stack
            sx={{
              paddingTop: { xs: "80px", md: "20px" },
              paddingBottom: { xs: "20px", md: "20px" },
            }}
          >
            {/* <BackwardButton /> */}
            <Outlet />
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default AdminLayout;
