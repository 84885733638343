import { ChangeEvent, SyntheticEvent, useState } from "react";

import { apiFetch } from "../../api/apiFetch";
import { useSelector } from "react-redux";
import { fireAlert } from "../../lib/fireAlert";
import { Stack, TextField } from "@mui/material";
import SubmitButton from "../../components/buttons/SubmitButton";

const SendMessageForm = () => {
  const userToken = useSelector(
    (state: UserGlobalState) => state.user.userToken
  );
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const phoneRegex = /^9[1-9][0-9]{7}$/;

  const submitSending = async (e: SyntheticEvent) => {
    e.preventDefault();
    const content =
      "مرحباً " +
      name +
      "\n" +
      "شكراً لزيارتك جناح شركة سديم التقنية  ويسعدنا أن نكون على تواصل دائم معك" +
      "\n" +
      "sadeem-tech.com";
    if (!name || !phoneRegex.test(phone)) {
      fireAlert("ادخل البيانات الصحيحة", "error");
      return;
    }
    await apiFetch(
      "/messages?sender=Resala",
      "POST",
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      JSON.stringify({
        name,
        phone,
        content,
      })
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.code) fireAlert(`تم ارسال رسالة الي  ${name}`, "success");
        else fireAlert(data.message, "error");
      });
  };

  return (
    <Stack
      py={2}
      gap="16px"
      maxWidth="600px"
      margin="auto"
      component="form"
      onSubmit={submitSending}
    >
      <TextField
        type="text"
        label="اسم الزائر"
        value={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
      />
      <TextField
        type="tel"
        label="رقم الهاتف"
        placeholder="9xxxxxxx"
        value={phone}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPhone(e.target.value)
        }
      />
      <SubmitButton label="ارسال" />
    </Stack>
  );
};

export default SendMessageForm;
