import * as yup from "yup";
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("ادخل البريد الالكترنى بشكل صحيح")
    .required("ادخل البريد الالكترونى"),
  password: yup
    .string()
    .min(8, "الرقم السرى قصير جدا")
    .max(32, "الرقم السري طويل حدا")
    .required("من فضلك ادخل الرقم السري"),
  is_admin: yup.boolean(),
  key: yup.string().when("is_admin", {
    is: false,
    then: (schema) => schema.required("مفتاح الدخول الي لوحة التحكم مطلوب"),
    otherwise: (schema) => schema,
  }),
});
